import {defineAsyncComponent} from "vue";
import {pick} from "xe-utils";

/**
 * 页面组件
 */
const routes = [
  {name: 'Home', title: '让每个决策都有数据支撑', component: defineAsyncComponent(() => import('@/views/Home.vue'))},
  {name: 'Voucher', title: '凭证列表', component: defineAsyncComponent(() => import('@/views/voucher/Index.vue'))},
  {name: 'VoucherForm', title: '凭证信息', component: defineAsyncComponent(() => import('@/views/voucher/VoucherForm.vue'))},
  {name: 'CheckList', title: '期末结转', component: defineAsyncComponent(() => import('@/views/checkout/CheckList.vue'))},
  {name: 'Checkout', title: '结账', component: defineAsyncComponent(() => import('@/views/checkout/Checkout.vue'))},
  {name: 'Account', title: '账套', component: defineAsyncComponent(() => import('@/views/setting/Account.vue'))},
  {name: 'Subject', title: '科目', component: defineAsyncComponent(() => import('@/views/setting/Subject.vue'))},
  {name: 'Initial', title: '期初', component: defineAsyncComponent(() => import('@/views/setting/Initial.vue'))},
  {name: 'VoucherWord', title: '凭证字', component: defineAsyncComponent(() => import('@/views/setting/VoucherWord.vue'))},
  {name: 'Currency', title: '币别', component: defineAsyncComponent(() => import('@/views/setting/Currency.vue'))},
  {name: 'AssistingAccounting', title: '辅助核算', component: defineAsyncComponent(() => import('@/views/setting/AssistingAccounting.vue'))},
  {name: 'PermissionSetting', title: '权限设置', component: defineAsyncComponent(() => import('@/views/setting/PermissionSetting.vue'))},
  {name: 'PersonalSetting', title: '个人设置', component: defineAsyncComponent(() => import('@/views/personal/PersonalSetting.vue'))},
  {name: 'DetailedAccounts', title: '明细账', component: defineAsyncComponent(() => import('@/views/accountbook/DetailedAccounts.vue'))},
  {name: 'GeneralLedger', title: '总账', component: defineAsyncComponent(() => import('@/views/accountbook/GeneralLedger.vue'))},
  {name: 'SubjectBalance', title: '科目余额', component: defineAsyncComponent(() => import('@/views/accountbook/SubjectBalance.vue'))},
  {name: 'SubjectSummary', title: '科目汇总', component: defineAsyncComponent(() => import('@/views/accountbook/SubjectSummary.vue'))},
  {name: 'MultiColumnLedger', title: '多栏账', component: defineAsyncComponent(() => import('@/views/accountbook/MultiColumnLedger.vue'))},
  {name: 'AgeAnalysis', title: '账龄分析表', component: defineAsyncComponent(() => import('@/views/accountbook/AgeAnalysis.vue'))},
  {name: 'AuxiliaryAccountingBalance', title: '辅助核算余额', component: defineAsyncComponent(() => import('@/views/accountbook/AuxiliaryAccountingBalance.vue'))},
  {name: 'AuxiliaryAccountingDetail', title: '辅助核算明细账', component: defineAsyncComponent(() => import('@/views/accountbook/AuxiliaryAccountingDetail.vue'))},
  {name: 'TimeSeries', title: '序时账', component: defineAsyncComponent(() => import('@/views/accountbook/TimeSeries.vue'))},
  {name: 'ReportView', title: '报表数据', component: defineAsyncComponent(() => import('@/views/report/ReportView.vue'))},
  {name: 'CashierAccount', title: '账户设置', component: defineAsyncComponent(() => import('@/views/cashier/CashierAccount.vue'))},
  {name: 'CashierIoType', title: '收支类别', component: defineAsyncComponent(() => import('@/views/cashier/CashierIoType.vue'))},
  {name: 'CashierJournal', title: '日记账', component: defineAsyncComponent(() => import('@/views/cashier/CashierJournal.vue'))},
  {name: 'InternalTransfer', title: '内部转账', component: defineAsyncComponent(() => import('@/views/cashier/InternalTransfer.vue'))},
  {name: 'FundsReport', title: '资金报表', component: defineAsyncComponent(() => import('@/views/cashier/FundsReport.vue'))},
  {name: 'CheckUp', title: '核对总账', component: defineAsyncComponent(() => import('@/views/cashier/CheckUp.vue'))},
  {name: 'Assets', title: '固定资产管理', component: defineAsyncComponent(() => import('@/views/assets/Assets.vue'))},
  {name: 'AssetsType', title: '固定资产类型', component: defineAsyncComponent(() => import('@/views/assets/AssetsType.vue'))},
  {name: 'AssetsDepreciationList', title: '折旧明细表', component: defineAsyncComponent(() => import('@/views/assets/AssetsDepreciationList.vue'))},
  {name: 'AssetsSummary', title: '固定资产汇总', component: defineAsyncComponent(() => import('@/views/assets/AssetsSummary.vue'))},
  {name: 'AssetsCheck', title: '固定资产核对总账', component: defineAsyncComponent(() => import('@/views/assets/AssetsCheck.vue'))},
  {name: 'OutputInvoice', title: '销项发票', component: defineAsyncComponent(() => import('@/views/invoice/OutputInvoice.vue'))},
  {name: 'IncomeInvoice', title: '进项发票', component: defineAsyncComponent(() => import('@/views/invoice/IncomeInvoice.vue'))},
  {name: 'BakUp', title: '备份恢复', component: defineAsyncComponent(() => import('@/views/setting/BakUp.vue'))},
  {name: 'Salary', title: '薪资', component: defineAsyncComponent(() => import('@/views/salary/Salary.vue'))},
  {name: 'SalaryDetail', title: '薪资', component: defineAsyncComponent(() => import('@/views/salary/SalaryDetail.vue'))},
  {name: 'BiReport', title: '自定义报表', component: defineAsyncComponent(() => import('./views/report/BiReport.vue'))},
  {name: 'FeeDetailReport', title: '费用明细表', component: defineAsyncComponent(() => import('./views/report/FeeDetailReport.vue'))},
  {name: 'Sync', title: '旧账同步', component: defineAsyncComponent(() => import('@/views/sync/Sync.vue'))},
  {name: 'Emp', title: '员工信息', component: defineAsyncComponent(() => import('@/views/salary/HrTable'))},
  {name: 'SocialSecurity', title: '保险设置', component: defineAsyncComponent(() => import('@/views/salary/SocialSecurity'))},
]

/**
 * 菜单
 */
const MENUS = [
  {
    title: '首页',
    key: 'Home',
    icon: 'fi fi-home',
    public: true,
  },
  {
    title: '新增凭证',
    key: 'VoucherForm',
    icon: 'fi fi-lpz',
    auth: 'voucher-vouchermanage-canedit'
  },
  {
    title: '凭证列表',
    key: 'Voucher',
    icon: 'fi fi-cpz',
    auth: 'voucher-vouchermanage-canview'
  },
  {
    title: '账簿管理',
    key: 'AccountBook',
    icon: 'fi fi-zbgl',
    children: [
      {
        title: '明细账',
        key: 'DetailedAccounts',
        auth: 'accountbook-subsidiaryledger-canview',
      },
      {
        title: '总账',
        key: 'GeneralLedger',
        auth: 'accountbook-generalledger-canview',
      },
      {
        title: '科目余额表',
        key: 'SubjectBalance',
        auth: 'accountbook-trialbalance-canview',
      },
      {
        title: '科目汇总表',
        key: 'SubjectSummary',
        auth: 'accountbook-categorizedaccountssummary-canview',
      },
      {
        title: '序时账',
        key: 'TimeSeries',
        auth: 'accountbook-journal-canview',
      },
      {
        title: '多栏账',
        key: 'MultiColumnLedger',
        auth: 'accountbook-journal-canview',
      },
      {
        title: '核算项目明细账',
        key: 'AuxiliaryAccountingDetail',
        auth: 'accountbook-aasubsidiaryledger-canview',
      },
      {
        title: '核算项目余额表',
        key: 'AuxiliaryAccountingBalance',
        auth: 'accountbook-aatrialbalance-canview',
      },
      {
        title: '账龄分析表',
        key: 'AgeAnalysis',
        auth: 'accountbook-journal-canview',
      }
    ]
  },
  {
    title: '报表管理',
    key: 'ReportList',
    icon: 'fi fi-bbgl',
    children: [
      {
        title: '费用明细表',
        key: 'FeeDetailReport',
        auth: 'report-custom-report-canview',
      },
      {
        title: '自定义报表',
        key: 'BiReport',
        auth: 'report-custom-report-canview',
      }
    ]
  },
  {
    title: '资金管理',
    key: 'funds',
    icon: 'fi fi-zjgl',
    children: [
      {
        title: '日记账',
        key: 'CashierJournal',
        auth: 'cash-cashjournal-canview',
      },
      {
        title: '内部转账',
        key: 'InternalTransfer',
        auth: 'cash-transfer-canview',
      },
      {
        title: '资金报表',
        key: 'FundsReport',
        auth: 'cash-report-canview',
      },
      {
        title: '核对总账',
        key: 'CheckUp',
        auth: 'cash-cdcheck-canview',
      },
      {
        title: '收支类别',
        key: 'CashierIoType',
        auth: 'cash-ietype-canview',
      },
      {
        title: '账户设置',
        key: 'CashierAccount',
        auth: 'cash-cdaccount-canview',
      }
    ]
  },
  {
    title: '工资管理',
    key: 'SalaryM',
    icon: 'fi fi-fpgl',
    children: [
      {
        title: '工资信息',
        key: 'Salary',
        auth: 'salary-salarymanage-canview',
      },
      {
        title: '员工信息',
        key: 'Emp',
        auth: 'salary-employinfo-canview',
      },
      {
        title: '五险一金设置',
        key: 'SocialSecurity',
        auth: 'salary-insuranceset-canview',
      },
    ]
  },
  {
    title: '发票管理',
    key: 'Invoice',
    icon: 'fi fi-fpgl',
    children: [
      {
        title: '销项发票',
        key: 'OutputInvoice',
        auth: 'invoice-output-canview',
      },
      {
        title: '进项发票',
        key: 'IncomeInvoice',
        auth: 'invoice-input-canview',
      }
    ]
  },
  {
    title: '固定资产',
    key: 'assets',
    icon: 'fi fi-gdzc',
    children: [
      {
        title: '固定资产管理',
        key: 'Assets',
        auth: 'fixedassets-fixedassets-card-canview',
      },
      {
        title: '固定资产类别',
        key: 'AssetsType',
        auth: 'fixedassets-fixedassettype-canview',
      },
      {
        title: '折旧明细表',
        key: 'AssetsDepreciationList',
        auth: 'fixedassets-depreciationledger-canview',
      },
      {
        title: '固定资产汇总',
        key: 'AssetsSummary',
        auth: 'fixedassets-fasummary-canview',
      },
      {
        title: '固定资产对账',
        key: 'AssetsCheck',
        auth: 'fixedassets-checkledger-canview',
      }
    ]
  },
  {
    title: '期末结转',
    key: 'Checkout',
    icon: 'fi fi-qmjz',
    auth: 'checkout-checkoutmanage-canview',
  },
  {
    title: '设置',
    key: 'Setting',
    icon: 'fi fi-sz',
    children: [
      {
        title: '账套',
        key: 'Account',
        auth: 'setting-accountset-canview',
      },
      {
        title: '科目',
        key: 'Subject',
        auth: 'setting-accountsubject-canview',
      },
      {
        title: '期初',
        key: 'Initial',
        auth: 'setting-initialbalance-canview',
      },
      {
        title: '币别',
        key: 'Currency',
        auth: 'setting-currency-canview',
      },
      {
        title: '凭证字',
        key: 'VoucherWord',
        auth: 'setting-vouchergroup-canview',
      }, {
        title: '辅助核算',
        key: 'AssistingAccounting',
        auth: 'setting-assistingaccount-canview',
      }, {
        title: '权限设置',
        key: 'PermissionSetting',
        auth: 'setting-backup-canview',
      }, {
        title: '旧账同步',
        key: 'Sync',
        auth: 'setting-sync-canview',
      }, {
        title: '备份恢复',
        key: 'BakUp',
        auth: 'setting-backup-canview',
      }
    ]
  }
];


export const routeList = routes

export const menuList = MENUS

/**
 * 生成用户菜单
 * @param astAdmin 是否是管理员
 * @param authorities 权限集
 */
export const generateUserMenu = (astAdmin = false, authorities = []) => {
  if (astAdmin) {
    return MENUS;
  }
  let userMenu = [];
  for (let menu of MENUS) {
    if (menu.public) {
      userMenu.push(menu)
    } else if (menu.hasOwnProperty("auth")) {
      authorities.includes(menu.auth) && userMenu.push(menu);
    } else {
      let children = menu.children.filter(val => authorities.includes(val.auth));
      if (children.length) {
        userMenu.push(Object.assign(pick(menu, ['title', 'key', 'icon']), {children}))
      } else if (menu.key === 'ReportList' && authorities.some(a => a.startsWith("report"))) {
        userMenu.push(Object.assign(pick(menu, ['title', 'key', 'icon']), {children}))
      }
    }
  }
  return userMenu;
}

export default {
  install(vue) {
    //注册页面
    routes.forEach(item => vue.component(item.name, item.component))
  }
}
